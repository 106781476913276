<template>
  <section class="section section--404">
    <div class="container">
      <div class="admin-wrapper">
        <div class="product-block">
          <h1 class="title">New Product</h1>
          <form class="form" @submit.prevent="createProduct">
            <div class="form__main">
              <div class="form__row">
                <label>
                  <span class="form__label">Product</span>
                  <label class="form__input-label">
                    <input v-model="product" type="text" class="form__input form__input--light" />
                  </label>
                </label>

                <label>
                  <span class="form__label">Product ID</span>
                  <label class="form__input-label">
                    <input v-model="productID" type="text" class="form__input form__input--small form__input--light" />
                  </label>
                </label>
              </div>
              <div class="form__row">
                <multiselect
                  v-model="filters"
                  :options="filtersList"
                  placeholder="Select filters"
                  label="name"
                  track-by="id"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :preserve-search="true"
                  :taggable="true"
                  :multiple="true"
                  class="form__select"
                  :disabled="!(!!subSubCategory || !!newSubSubCat)"
                  @tag="addFilter"
                >
                  <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                    <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                  </span>
                </multiselect>
              </div>
              <div class="btn-wrap"><button type="submit" class="btn btn--bgr">Save Product</button></div>
            </div>
            <div class="form__sidebar">
              <div class="form__wrap">
                <div class="form__label">Category</div>
                <multiselect
                  :value="category"
                  :options="categories"
                  track-by="id"
                  label="name"
                  :searchable="false"
                  :show-labels="false"
                  class="form__select"
                  :disabled="!!newCat"
                  @select="selectCategory"
                >
                  <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                    <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                  </span>
                </multiselect>

                <div v-if="!inputCat" class="form__add" @click="inputCat = true">
                  <div class="form__add-new">Add New Category</div>
                </div>
                <div v-if="inputCat">
                  <label class="form__input-label">
                    <input v-model="newCat" type="text" class="form__input" />
                    <button class="icon-close" @click="newCat = null">×</button>
                  </label>
                </div>
              </div>

              <div class="form__wrap">
                <div class="form__label">SubCategory</div>
                <multiselect
                  :value="subCategory"
                  :options="subCats"
                  track-by="id"
                  label="name"
                  :searchable="false"
                  :show-labels="false"
                  class="form__select"
                  :disabled="!!!subCats.length || !!newCat || !!newSubCat"
                  @select="selectSubCategory"
                >
                  <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                    <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                  </span>
                </multiselect>

                <div
                  v-if="!inputSubCat"
                  class="form__add"
                  :class="{'form__add--disabled': !(!!category || !!newCat)}"
                  @click="inputSubCat = true"
                >
                  <div class="form__add-new">Add New SubCategory</div>
                </div>
                <div v-if="inputSubCat">
                  <label class="form__input-label">
                    <input v-model="newSubCat" type="text" class="form__input" />
                    <button class="icon-close" @click="newSubCat = null">×</button>
                  </label>
                </div>
              </div>

              <div class="form__wrap">
                <div class="form__label">Sub SubCategory</div>
                <multiselect
                  :value="subSubCategory"
                  :options="subSubCats"
                  track-by="id"
                  label="name"
                  :searchable="false"
                  :show-labels="false"
                  class="form__select"
                  :disabled="!!!subSubCats.length || !!newCat || !!newSubCat || !!newSubSubCat"
                  @select="selectSubSubCategory"
                >
                  <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                    <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                  </span>
                </multiselect>

                <div
                  v-if="!inputSubSubCat"
                  class="form__add"
                  :class="{'form__add--disabled': !(!!subCategory || !!newSubCat)}"
                  @click="inputSubSubCat = true"
                >
                  <div class="form__add-new">Add New Sub SubCategory</div>
                </div>
                <div v-if="inputSubSubCat">
                  <label class="form__input-label">
                    <input v-model="newSubSubCat" type="text" class="form__input" />
                    <button class="icon-close" @click="newSubSubCat = null">×</button>
                  </label>
                </div>
              </div>
            </div>
          </form>

          <!-- <form class="form" @submit.prevent="uploadPhoto">
            <div class="form__row">
              <label for="file-upload" class="form__label-wrap center">
                <div class="form__img-add-wrap">
                  <i class="ri-add-circle-lineform__img-add" "></i> Add Photo
                </div>
              </label>
              <input id="file-upload" type="file" multiple class="form__input" @change="addImage" />
              <label>
                <span class="form__label">Product ID</span>
                <input v-model="productPhotoID" type="text" class="form__input form__input--light" />
              </label>
              <div class="btn-wrap">
                <button type="submit" class="btn btn--bgr">Upload Photo</button>
              </div>
            </div>
          </form> -->

          <!-- <div v-if="productInfo && productInfo.photos">
            <img v-for="(img, i) of productInfo.photos" :key="i" :src="img.url" @click="selectPhoto(img)" />
            <div class="btn-wrap"><button class="btn btn--bgr" @click="removePhotos">Delete photos</button></div>
          </div> -->
        </div>
        <div class="cat-block"></div>
      </div>
      <ul class="results">
        <li v-for="item in products" :key="item.id" class="results__item">
          <div class="results__img-wrap">
            <img src="@/assets/images/products.png" alt="product image" class="results__img" />
          </div>
          <div class="results__info">
            <h4 class="results__title">{{ item.product }}</h4>
            <p class="results__desc"></p>
            <router-link :to="/products/ + item.productId" class="link">Edit</router-link>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters, mapActions, mapState} from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
  name: 'AdminPage',
  components: {
    Multiselect
  },
  data() {
    return {
      product: null,
      productID: null,

      category: null,
      newCat: null,
      inputCat: false,

      subCategory: null,
      subCats: [],
      newSubCat: null,
      inputSubCat: false,

      subSubCategory: null,
      subSubCats: [],
      newSubSubCat: null,
      inputSubSubCat: false,

      filters: [],
      filtersList: [],
      newFilters: []

      // productPhotoID: null,
      // files: [],
      // selectedPhotos: [],
    }
  },
  computed: {
    ...mapGetters({
      categories: 'main/categories'
    }),
    ...mapState({
      productInfo: s => s.products.product,
      products: s => s.products.products
    })
  },
  async created() {
    await this.getProduct('001234')
  },
  methods: {
    ...mapActions({
      getProduct: 'products/fetchProduct',
      create: 'products/create',
      changeCategory: 'main/changeCategory',
      fetchConstants: 'main/fetchConstants',
      addPhoto: 'products/addPhoto',
      delPhotos: 'products/delPhotos'
    }),
    //================================================================
    selectCategory(cat) {
      this.category = cat
      this.subCategory = this.newSubCat = this.newSubSubCat = null
      this.inputCat = this.inputSubCat = this.inputSubSubCat = false
      this.filtersList = this.filters = []
      this.subCats = cat.items || []
    },
    selectSubCategory(cat) {
      this.subCategory = cat
      this.subSubCategory = this.newSubSubCat = null
      this.inputSubCat = this.inputSubSubCat = false
      this.filtersList = this.filters = []
      this.subSubCats = cat.items || []
    },
    selectSubSubCategory(cat) {
      this.subSubCategory = cat
      this.filters = []
      this.filtersList = cat.items || []
    },
    addFilter(filter) {
      const newFilter = {
        id: this.filtersList.length,
        name: filter
      }
      this.newFilters.push(newFilter)
      this.filtersList.push(newFilter)
      this.filters.push(newFilter)
    },
    //================================================================
    async createProduct() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      if (this.newCat) {
        this.categories.push({
          id: this.categories.length,
          name: this.newCat,
          items: []
        })
      }
      const fCat = this.categories.find(c => c.name === this.newCat || this.category)?.items
      if (this.newSubCat) {
        fCat.push({
          id: fCat.length,
          name: this.newSubCat,
          items: []
        })
      }
      if (this.newSubSubCat) {
        const fSubCat = fCat.find(c => c.name === this.newSubCat || this.subCategory).items
        fSubCat.push({
          id: fSubCat.length,
          name: this.newSubSubCat,
          items: []
        })

        if (this.filters.length) {
          fSubCat.find(c => c.name === this.newSubSubCat).items = this.filters
        }
      }
      const formData = {
        category: this.newCat || this.category,
        subCategory: this.newSubCat || this.subCategory,
        subSubCategory: this.newSubSubCat || this.subSubCategory,
        product: this.product,
        productId: this.productID
      }
      this.changeCategory(this.categories)
      if (this.filters.length) formData.filters = this.filters.map(f => f.name)
      await this.create(formData)
      this.reset()
      await this.fetchConstants()
    },
    // addImage(e) {
    //   this.files = e.target.files
    // },
    // async uploadPhoto() {
    //   if (!this.productPhotoID) return
    //   await this.addPhoto({filesList: this.files, id: this.productPhotoID, urlsList: this.productInfo.photos})
    // },
    // selectPhoto(img) {
    //   this.selectedPhotos.push(img)
    // },
    // removePhotos() {
    //   if (!this.selectedPhotos.length) return
    //   const newPhotosList = _.differenceWith(this.productInfo.photos, this.selectedPhotos, _.isEqual)
    //   this.delPhotos({files: this.selectedPhotos, id: this.productInfo.productId, filesList: newPhotosList})
    // },

    reset() {
      this.category =
        this.catInfo =
        this.subCategory =
        this.subCatInfo =
        this.subSubCategory =
        this.product =
        this.productID =
        this.filter =
        this.newCat =
        this.newSubCat =
        this.newSubSubCat =
          null
      this.filters = this.filtersList = this.newFilters = this.subCats = this.subSubCats = []
      this.inputCat = this.inputSubCat = this.inputSubSubCat = false
    }
  },
  validations: {
    category: {
      required: requiredIf(function () {
        if (!this.newCat) return true
      })
    },
    subCategory: {
      required: requiredIf(function () {
        if (!this.newSubCat) return true
      })
    },
    product: {required},
    productID: {required}
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import 'style';
</style>
